
  import { defineComponent, onMounted, ref, watch } from "vue"; 
  import { IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonCheckbox, IonList, alertController  } from '@ionic/vue';
  import { useAuthStore, useImageStore } from '@/store';
  import { useRoute } from 'vue-router'
  import router from "@/router";
  import { not as $not } from "../../model/not";
  import { updateUser, appsList } from "../../model/apps.js";
  
  import { _firebase as $_firebase } from "../../model/firebase";
  import { sendPasswordResetEmail } from 'firebase/auth';
  //import { onSnapshot, query, collectionGroup, where } from "firebase/firestore"; 
  //const db   =  $_firebase.firestore()
  const auth = $_firebase.auth() 

  export default defineComponent({
    name: 'UserProfile',
    props: { 
        isMobile: Boolean,
    },
    components: { IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonCheckbox, IonList }, // IonModal, IonContent 
    setup() {
       
       
      const authStore      = useAuthStore();
      const route          = useRoute()
      const imageStore     = useImageStore();
      const { logoutUser, user, profile } = authStore;
      const layout         = ref({
                                    subscriptions: false,
                                    apps:          null,
                                    section:       null,
                                    sections:      [],
                                    notify:   {
                                                email:    false,
                                                whatsapp: false,
                                                sms:      false
                                              },
                                    terms:        false,
                                    acceptTerms:  false,
                                    query:        null,
                                    changePass:   {
                                                    box: false,
                                                    pass: null,
                                                    pass2: null
                                                  }
                                })

      const logoutApp = async () => {
        await clearUserData();
        await logoutUser();
        router.replace("/login");
        window.location.reload();
      }

      /*const getSubscriptions = async () => {
        const collectionPath = "alba/" + profile.controllerId + "/subscriptions";
        try {
          const q = query(collectionGroup(db, collectionPath), where("deleted", "==", false));
          onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              console.log(doc.id, " => ", doc.data());
            });
          });
        } catch (error) {
          console.log(error);
        }
      }*/

      const clearUserData = async () => {
        localStorage.removeItem('user');
        localStorage.removeItem('profile');
        localStorage.removeItem('image');
      }

      const terms = () => {
       
      }
       
      const saveProfile = async () => {
        let validate = true
        let required = ['first', 'last']
        required.forEach((field) => {  if(!profile[field])validate = false })
        //if(profile.changePass != profile.changePass2)validate = false
        //if (!profile.notifications)validate = false
        if(validate){
            await updateUser({ id: profile.uid,first: profile.first, last: profile.last })
            $not.toastApp({ header: 'Mi cuenta', message: 'Perfil actualizado correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
        }else $not.toastApp({ header: 'Mi cuenta', message: 'Error al actualizar el perfil.  Revise los datos obligatorios y los términos del servicio.', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
      }

      const saveNotify = async () => {
        let validate = true
        if (!layout.value.acceptTerms)validate = false
        if(validate){
            await updateUser({ notify: profile.notify, id: profile.uid })
            $not.toastApp({ header: 'Mi cuenta', message: 'Perfil actualizado correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
        }else $not.toastApp({ header: 'Mi cuenta', message: 'Error al actualizar el perfil.  Revise los datos obligatorios y los términos del servicio.', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
      }
  
      const initProfile = async () => {
        layout.value.sections.push({ name: 'profile',         title: 'Mi perfil', icon: 'bi bi-person-circle'})
        if(profile && !profile.isAgent)layout.value.sections.push({ name: 'notify',          title: 'Notificaciones', icon: 'bi bi-send'})
        //{ name: 'subscription',    title: 'Suscripciones', icon: 'bi bi-bell'},
                                              

        if(!layout.value.section)
           if(route.query.section) layout.value.section = route.query.section
           else layout.value.section = 'profile'
            layout.value.apps = await appsList({ byUser: profile });
            if(layout.value.apps?.apps){
                for (const [key] of Object.entries(layout.value.apps.apps)) {
                    if(layout.value.apps.apps[key]?.limits?.notify.sms)layout.value.notify.sms = true
                    if(layout.value.apps.apps[key]?.limits?.notify.whatsapp)layout.value.notify.whatsapp = true
                    if(layout.value.apps.apps[key]?.limits?.notify.email)layout.value.notify.email = true
                }   
            }
      }

      const changePass = async () => {
        const alert = await alertController.create({
            header:  "¿Está seguro que quiere continuar? Se enviará un email a su correo " + profile.email + " para modificar la contraseña.",
            cssClass: 'friCustomAlert',
            buttons: [ 
                        {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel' },  
                        {text: 'Continuar',cssClass: 'alert-button-confirm', handler: async () => { 
                            sendPasswordResetEmail(auth, profile.email).then(() => { $not.toastApp({ header: 'Mi cuenta', message: 'Se ha enviado un email para restablecer la contraseña', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
                            }) .catch((error) => { $not.toastApp({ header: 'Mi cuenta', message: 'Error al enviar el email para restablecer la contraseña' + error, position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' }) })
                        }
                        }
                      ],
        });
        await alert.present();
        /*
        let validate = true
        let required = ['pass', 'pass2']
        required.forEach((field) => { if(!layout.value.changePass[field])validate = false })
        //minimum 8 characters
        if(validate){
            console.log(layout.value.changePass.pass.length)
            if(layout.value.changePass.pass.length >= 8){
                if(layout.value.changePass.pass == layout.value.changePass.pass2){
                    layout.value.changePass.box   = false
                    layout.value.changePass.pass  = null
                    layout.value.changePass.pass2 = null
                }else $not.toastApp({ header: 'Mi cuenta', message: 'Las contraseñas no coinciden', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
            }else $not.toastApp({ header: 'Mi cuenta', message: 'La contraseña debe tener al menos 8 caracteres', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
        }else $not.toastApp({ header: 'Mi cuenta', message: 'Error al actualizar la contraseña.  Revise los datos obligatorios.', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
        */
      }

      onMounted( async () => { 
        await initProfile()
      });

      watch(() => route.query, async (query) => {
            if (query){
                layout.value.query   = query; 
                layout.value.section = query.section;
                if(layout.value.section == 'logout')await logoutApp();
            }
      });
  
      return { logoutApp, user, profile, imageStore, saveProfile, layout, terms, saveNotify, changePass }; //getSubscriptions
    }
    
  })
